body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.general {
  background-color: #060b26;
  width: 330px;
  height: 250px;
  margin-top: 15%;
  margin-left: 35%;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.tituloapp{
   color: #f5f5f5;
    text-align: center;
  margin-top: auto;
}


input {
  font-family: "Ubuntu", sans-serif;
  outline: none;
  font-size: 1rem;

}

.title-login {
  text-align: center;
  text-transform: uppercase;
  font-size: 30px;
  color:#f5f5f5;
  margin-left:20px
}

.myform {
  box-sizing: border-box;
  max-width: 330px;
}

.myform form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.myform form label {
  font-weight: bold;
}

.myform form input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #bbbbbb;
  border-radius: 3px;
  margin-bottom: 5px;
}

.myform form input:hover {
  border-color: #999999;
}

.myform form input:focus {
  border-color: #0d6efd;
}


.home {
  text-align: center;
}

.img-productos {
  width: 50px;
  height: 50px;
}

.home h1 span {
  font-weight: normal;
  font-size: 16px;
}

.button-login {
  font-family: "Ubuntu", sans-serif;
  outline: none;
  font-size: 1rem;
  margin-left: 30%;
  font-weight: bold;

}

.button{
  margin: 5px;
}

.blue-text{
  color:#0d6efd;
}

.menu-perfil{
    border-radius: 30%;
}

.img-productos-card {
  width: 100px;
  height: 100px;
}

.tabla {
   background-color: white;
  justify-content: flex-end;
  max-height: 100vh;
  overflow-y: auto;
}

.collapseStyle {
  .ant-menu-title-content {
    color:  #0000 !important;
  }
  .ant-menu > .ant-menu-item:hover,
  .ant-menu > .ant-menu-submenu:hover,
  .ant-menu > .ant-menu-item-active,
  .ant-menu> .ant-menu-submenu-active,
  .ant-menu > .ant-menu-item-open,
  .ant-menu> .ant-menu-submenu-open,
  .ant-menu > .ant-menu-submenu-selected,
  .ant-menu > .ant-menu-item-selected  {
    background-color: #0d6efd !important;
  }
}

.expandedStyle {
  .ant-menu-title-content {
    color:  #BFBFBF !important;
  }
        .ant-menu > .ant-menu-item:hover,
      .ant-menu > .ant-menu-submenu:hover,
      .ant-menu > .ant-menu-item-active,
      .ant-menu> .ant-menu-submenu-active,
      .ant-menu > .ant-menu-item-open,
      .ant-menu> .ant-menu-submenu-open,
      .ant-menu > .ant-menu-submenu-selected,
      .ant-menu > .ant-menu-item-selected  {
        background-color: #0d6efd !important;
      }
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.space-align-block {
  flex: none;
  margin: 8px 4px;
  padding: 4px;
  border: 1px solid #40a9ff;
}
.space-align-block .mock-block {
  display: inline-block;
  padding: 32px 8px 16px;
  background: rgba(150, 150, 150, 0.2);
}





 






